import React from "react";
import { Route, Routes } from "react-router-dom";
import Home02 from "../pages/Home/Home02";
import Team from "../pages/Team/Team";
import Contact from "../pages/Contact/Contact";
  import IntegratorOnboarding from "../pages/Integrator/Onboarding";
import Page404 from "../pages/Error/404";
import About from "../pages/About/About";
import CompanyForm from "../pages/Advertiser/Onboarding/CompanyForm";
import Casestudy from "../pages/Integrator/Casestudy/Casestudy";
import TryDemo from "../pages/TryDemo/TryDemo";
import AdvertisementForm from "../pages/Advertiser/Onboarding/AdvertisementForm";

const PublicRoutes = () => {
  return (
    <Routes>
      {/* Rename all the components properly  */}

      <Route path="/" element={<Home02 />} />
      {/* <Route path="/about" element={<About />} /> */}

      {/* <Route path="/team" element={<Team />} /> */}
      <Route path="/contact" element={<Contact />} />
      {/* <Route path="/advertiser" element={<CompanyForm />} /> */}
      {/* <Route path="/advertiser-campaign" element={<AdvertisementForm />} /> */}
      {/* <Route path="/integrator" element={<IntegratorOnboarding />} /> */}
      {/* <Route path="/integrator/casestudy" element={<Casestudy />} /> */}
      {/* <Route path="/trydemo" element={<TryDemo/>} /> */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default PublicRoutes;
