import img1 from "../images/icon/icon3.png";
import img2 from "../images/icon/icon4.png";
import img3 from "../images/icon/icon1.png";
import img4 from "../images/icon/icon2.png";

const dataBox = [
  {
    id: 1,
    img: img1,
    title: "Advertisers",
    desc: "They submit the project with Ad details to Marketplace, Decides % commissions",
  },
  {
    id: 2,
    img: img2,
    title: "Marketplace",
    desc: "A place where Advertiser will Submit their Advertiser & Different Integrators can integrate the Ads",
  },
  {
    id: 3,
    img: img3,
    title: "Integrators ",
    desc: "Connect with Advertiser through Marketplace and start showing Ads in their platform",
  },
  {
    id: 4,
    img: img4,
    title: "End Users",
    desc: "Users who will watch Ads and get benefits like gasless transactions,rewards etc",
  },
];

export default dataBox;
