import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

Advertise.propTypes = {
  data: PropTypes.array,
};

function Advertise(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "Advertise",
    heading: "Advertise your stuff directly to on-chain users",
    desc: "Why should web3 products & services be advertised like web2? Advertise to the ones who matter",
  });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [stopChanging, setStopChanging] = useState(true);

  const handleMouseHover = (index) => {
    setCurrentImageIndex(index);
    setStopChanging(true);
  };

  const handleMouseLeave = () => {
    setStopChanging(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!stopChanging) {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 3);
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [stopChanging]);

  const img1 = "https://lh3.googleusercontent.com/d/11sGCqpnJ97j5IHNnGE2I1mW9WK3Bg_no"
  const img2 = "https://lh3.googleusercontent.com/d/1WUdXBPxrkOhKx09UqmKxqJckx-krKzFl"
  const img3 = "https://lh3.googleusercontent.com/d/1ulZBKu89W4cM3giVk1Q5VSfW-YHgzckM"

  return (
    <section id="advertiser" className="portfolio">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p className="desc mb-4 fs-6">{dataBlock.desc}</p>
            </div>
          </div>
          <div className="col-xl-6 col-md-6 center__portfolio__left">
            <div className="portfolio__left">
              {data.map((idx, index) => (
                <div
                  key={idx.id}
                  onMouseOver={() => handleMouseHover(index)}
                  onMouseLeave={handleMouseLeave}
                  className={
                    currentImageIndex === index
                      ? "portfolio-box selected__div"
                      : "portfolio-box "
                  }
                >
                  <div className="step">Step {idx.id}</div>
                  <div className="icon">
                    <img src={idx.img} alt="DeliverE" />
                  </div>
                  <div className="content">
                    <h5 className="title">{idx.title}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-xl-6 col-md-6">
            <div className="portfolio__right">
              <div className="mx-auto">
                <img
                  className={
                    currentImageIndex === 0
                      ? "image__div ratio ratio-1x1 p-5"
                      : "visually-hidden"
                  }
                  src={currentImageIndex === 0 ? img1 : ""}
                  alt="img"
                />
                <img
                  className={
                    currentImageIndex === 1
                      ? "image__div ratio ratio-1x1 p-5"
                      : "visually-hidden"
                  }
                  src={currentImageIndex === 1 ? img2 : ""}
                  alt="img"
                />
                <img
                  className={
                    currentImageIndex === 2
                      ? "image__div ratio ratio-1x1 p-5"
                      : "visually-hidden"
                  }
                  src={currentImageIndex === 2 ? img3 : ""}
                  alt="img"
                />
              </div>

              <Link to="/contact" className="action-btn">
                <span>Register as Advertiser</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Advertise;
