import { useNavigate } from "react-router-dom";

function Page404(props) {
    const navigate=useNavigate()
  return (
    <>
      {" "}
      <h3 className="center" style={{ marginTop: "10%" }}>
        Opps! Page Not Found
      </h3>
      <button
        className="d-flex justify-content-center m-auto  mt-4 p-2"
        style={{ borderRadius: "10%", background: "white", color: "black" }}
        onClick={()=>{
            navigate('/')
        }}
      >
        Go Back{" "}
      </button>
    </>
  );
}

export default Page404;
