import { useAccount } from "wagmi";
import Header from "../components/header/Header";
import PrivateRoutes from "./privateRoutes";
import PublicRoutes from "./publicRoutes";
import { useLocation } from "react-router-dom";
import Page404 from "../pages/Error/404";

const AppRoutes = () => {
  const { isConnected } = useAccount();
  const currentLocation = useLocation();
  return (
    <>
      {currentLocation.pathname === "/advertiser-dashboard" ||
      currentLocation.pathname === "/integrator-dashboard" ? (
        <>
          {isConnected ? (
            <PrivateRoutes />
          ) : (
            <>
              <Header />
              <Page404 />
            </>
          )}
        </>
      ) : (
        <>
          <Header />
          <PublicRoutes />
        </>
      )}
    </>
  );
};

export default AppRoutes;
