import React from "react";
import { Route, Routes } from "react-router-dom";
import AdvertiserDashboard from "../pages/Advertiser/Dashboard";
import IntegratorDashboard from "../pages/Integrator/Dashboard";

const PrivateRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/advertiser-dashboard" element={<AdvertiserDashboard />} /> */}
      {/* <Route path="/integrator-dashboard" element={<IntegratorDashboard />} /> */}
    </Routes>
  );
};

export default PrivateRoutes;
