import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'

const dataAdvertise = [
    {
        id: 1,
        img: img1,
        title: 'Register As Advertiser',
        desc: 'Register with us as a integrator on the platform and get the acess to the advertisers that are registerd with us.  '
    },
    {
        id: 2,
        img: img2,
        title: 'Allocate Advertisement Pool',
        desc: 'Get all the acess to the apis and configure on the basis of which advertisers to choose and integrate for your daap'
    },
    {
        id: 3,
        img: img3,
        title: 'Get Targeted on-chain Impressions',
        desc: 'APIs are here to be connected '
    },
]

export default dataAdvertise;