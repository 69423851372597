import React from "react";
import App from "./App";
import "./App.scss";
import ReactDOM from "react-dom/client";
import ScrollToTop from "./ScrollToTop";
import { BrowserRouter } from "react-router-dom";

import { EIP6963Connector, createWeb3Modal } from "@web3modal/wagmi/react";
import { walletConnectProvider } from "@web3modal/wagmi";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";

import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { polygonMumbai } from "viem/chains";
import { Toaster } from "react-hot-toast";

const projectId = "904623217cd89ca1a411940339ad0a1c";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const { chains, publicClient } = configureChains(
  [polygonMumbai],
  [walletConnectProvider({ projectId })]
);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({
      chains,
      options: { projectId, showQrModal: false, metadata },
    }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: metadata.name },
    }),
  ],
  publicClient,
});

createWeb3Modal({ wagmiConfig, projectId, chains });

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.Fragment>
    <BrowserRouter>
      <WagmiConfig config={wagmiConfig}>
        <ScrollToTop />
        <App />
        <Toaster/>
      </WagmiConfig>
    </BrowserRouter>
  </React.Fragment>
);
