const dataFaqs = [
        {
            id: 1,
            title: "What is DeliverE?",
            text : "DeliverE is a revolutionary platform that connects Crypto Wallet Developers and Crypto Advertisers, enabling users to enjoy gasless transactions by viewing advertisements. Our service enhances the transaction experience for users while offering targeted advertising opportunities.",
            show: ""

        },
        {
            id: 2,
            title: "How does DeliverE's advertisement-layer work?",
            text : "Our advertisement-layer integrates with crypto wallets, allowing users to view a short ad before completing a transaction. In return for viewing the ad, the user's gas fee is sponsored, making the transaction gasless.",
            show: ""
        },
        {
            id: 3,
            title: "How can Wallet Developers integrate DeliverE's API?",
            text : "Wallet Developers can easily integrate DeliverE's API by registering their product, selecting suitable advertiser partners from our platform, and then monetizing the ad views. Our team provides support throughout the integration process.",
            show: ""
        },
        {
            id: 4,
            title: "What benefits does DeliverE offer to Crypto Wallet Developers?",
            text : "DeliverE offers Wallet Developers a new revenue stream through ad commissions, enhances user experience by enabling gasless transactions, and provides an innovative way to monetize their user base.",
            show: ""
        },
    ]

export default dataFaqs;