import React from "react";

import PageTitle from "../../components/pagetitle/PageTitle";
import Faq3 from "../../components/faqs/Faq3";
import dataFaqs from "../../assets/fake-data/data-faq";
import img from "../../assets/images/PNG/OBJECT ILLUSTRATION-02.png";
import Footer2 from "../../components/footer/Footer2";

function Contact(props) {
  return (
    <div className="wrapper">
      {/* <PageTitle title="Contact" /> */}

      <section className="touch contact__container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="block-text center">
                <h6 className="sub-heading">
                  <span>Get in Touch!</span>
                </h6>
                <h3 className="heading contact__heading">
                  Let’s Start Working Together
                </h3>
              </div>
              <div className="touch__main">
                <div className="info">
                  <h5>Contact information</h5>
                  <ul className="list">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                        id="telegram"
                        width="16"
                        height="16"
                        fill="white"
                      >
                        <path d="M22.26465,2.42773a2.04837,2.04837,0,0,0-2.07813-.32421L2.26562,9.33887a2.043,2.043,0,0,0,.1045,3.81836l3.625,1.26074,2.0205,6.68164A.998.998,0,0,0,8.134,21.352c.00775.012.01868.02093.02692.03259a.98844.98844,0,0,0,.21143.21576c.02307.01758.04516.03406.06982.04968a.98592.98592,0,0,0,.31073.13611l.01184.001.00671.00287a1.02183,1.02183,0,0,0,.20215.02051c.00653,0,.01233-.00312.0188-.00324a.99255.99255,0,0,0,.30109-.05231c.02258-.00769.04193-.02056.06384-.02984a.9931.9931,0,0,0,.20429-.11456,250.75993,250.75993,0,0,1,.15222-.12818L12.416,18.499l4.03027,3.12207a2.02322,2.02322,0,0,0,1.24121.42676A2.05413,2.05413,0,0,0,19.69531,20.415L22.958,4.39844A2.02966,2.02966,0,0,0,22.26465,2.42773ZM9.37012,14.73633a.99357.99357,0,0,0-.27246.50586l-.30951,1.504-.78406-2.59307,4.06525-2.11695ZM17.67188,20.04l-4.7627-3.68945a1.00134,1.00134,0,0,0-1.35352.11914l-.86541.9552.30584-1.48645,7.083-7.083a.99975.99975,0,0,0-1.16894-1.59375L6.74487,12.55432,3.02051,11.19141,20.999,3.999Z"></path>
                      </svg>
                      <p>
                        <a href="https://web.telegram.org/k/#@cryptorohittt">
                          deliverE
                        </a>
                      </p>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                        id="twitter"
                        width="16"
                        height="16"
                        fill="white"
                      >
                        <path d="M22.99121,3.9502a.99942.99942,0,0,0-1.51074-.85938,7.47956,7.47956,0,0,1-1.873.793,5.15234,5.15234,0,0,0-3.374-1.24219,5.23238,5.23238,0,0,0-5.22363,5.06348A11.03194,11.03194,0,0,1,4.19629,3.78125,1.01154,1.01154,0,0,0,3.33887,3.416a.99852.99852,0,0,0-.78516.5,5.2755,5.2755,0,0,0-.24219,4.76855l-.00195.00195a1.0411,1.0411,0,0,0-.49512.88868,3.04174,3.04174,0,0,0,.02637.43945,5.1854,5.1854,0,0,0,1.56836,3.3125.99813.99813,0,0,0-.06641.76953,5.20436,5.20436,0,0,0,2.36231,2.92187,7.46464,7.46464,0,0,1-3.58985.44825.99975.99975,0,0,0-.665,1.833A12.94248,12.94248,0,0,0,8.46,21.36133,12.7878,12.7878,0,0,0,20.9248,11.998,12.82166,12.82166,0,0,0,21.46,8.35156c0-.06543,0-.13281-.001-.20019A5.76963,5.76963,0,0,0,22.99121,3.9502ZM19.68457,7.16211a.995.995,0,0,0-.2334.70215c.00977.165.00879.331.00879.4873a10.82371,10.82371,0,0,1-.4541,3.08106A10.68457,10.68457,0,0,1,8.46,19.36133a10.93791,10.93791,0,0,1-2.55078-.30078,9.47951,9.47951,0,0,0,2.94238-1.56348A1.00033,1.00033,0,0,0,8.25,15.71094a3.208,3.208,0,0,1-2.21387-.93457q.22413-.04248.44532-.10547a1.00026,1.00026,0,0,0-.08008-1.94336,3.19824,3.19824,0,0,1-2.25-1.72559,5.29929,5.29929,0,0,0,.54492.0459,1.02093,1.02093,0,0,0,.9834-.69629A.9998.9998,0,0,0,5.2793,9.21484,3.19559,3.19559,0,0,1,3.85547,6.542c0-.0664.00195-.13281.00586-.19824a13.01365,13.01365,0,0,0,8.209,3.47949,1.02046,1.02046,0,0,0,.81739-.3584,1.00037,1.00037,0,0,0,.206-.86816,3.15653,3.15653,0,0,1-.08691-.72852A3.23,3.23,0,0,1,16.2334,4.6416a3.18428,3.18428,0,0,1,2.34472,1.02051A.993.993,0,0,0,19.499,5.96a9.27073,9.27073,0,0,0,1.21192-.32226A6.68126,6.68126,0,0,1,19.68457,7.16211Z"></path>
                      </svg>
                      <p>
                        <a href="https://web.telegram.org/k/#@cryptorohittt">
                          deliverE
                        </a>
                      </p>
                    </li>
                    <li>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.5 4.5V11.5C14.5 11.8978 14.342 12.2794 14.0607 12.5607C13.7794 12.842 13.3978 13 13 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V4.5M14.5 4.5C14.5 4.10218 14.342 3.72064 14.0607 3.43934C13.7794 3.15804 13.3978 3 13 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5M14.5 4.5V4.662C14.5 4.9181 14.4345 5.16994 14.3096 5.39353C14.1848 5.61712 14.0047 5.80502 13.7867 5.93933L8.78667 9.016C8.55014 9.16169 8.2778 9.23883 8 9.23883C7.7222 9.23883 7.44986 9.16169 7.21333 9.016L2.21333 5.94C1.99528 5.80569 1.81525 5.61779 1.69038 5.3942C1.56551 5.1706 1.49997 4.91876 1.5 4.66267V4.5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p>
                        <a href="https://web.telegram.org/k/#@cryptorohittt">
                          deliverE@gmail.com
                        </a>
                      </p>
                    </li>
                  </ul>

                  <div className="image">
                    <img src={img} alt="DeliverE" />
                  </div>
                </div>

                <form action="#" className="form-box">
                  <div className="row">
                    <div className="col">
                      <label>Your name</label>
                      <input type="text" placeholder="Enter your name" className="form-control" />
                    </div>
                    <div className="col">
                      <label>Your email</label>
                      <input type="email" placeholder="Enter your email" className="form-control" />
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col">
                      <label>Your phone</label>
                      <input type="text" className="form-control" />
                    </div> */}
<div className="col">
  <label>Category</label>
  <select className="form-control" defaultValue="" placeholder="Select your Category">
    <option className="d-none" disabled value="">Select your Category</option>
    <option>General</option>
    <option>Advertiser</option>
    <option>Integrator</option>
  </select>
</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label>Message</label>
                      <textarea
                        name="mess"
                        id="mess"
                        cols="30"
                        rows="10"
                        placeholder="Please write a descriptive message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mb-0">
                    <div className="col">
                      <button className="action-btn  d-block mx-auto ">
                        <span>Send Now</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Faq3 data={dataFaqs} /> */}

      <Footer2 />
    </div>
  );
}

export default Contact;
