import React, {useState} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import line from "../../assets/images/background/line-2.png";
import twitterWhiteImg from "../../assets/images/team/twitter-white.svg"
import twitterImg from "../../assets/images/team/twitter.svg"
import telegramWhiteImg from "../../assets/images/team/telegram-white.svg"
import telegramImg from "../../assets/images/team/telegram.svg"
import linkedinImg from "../../assets/images/team/linkedin.svg"
import linkedinWhiteImg from "../../assets/images/team/linkedin-white.svg"

Team2.propTypes = {
  data: PropTypes.array,
};

function Team2(props) {
  const [linkedin, setlinkedin] = useState(linkedinImg)
  const [telegram, setTelegram] = useState(telegramImg)
  const [twitter, setTwitter] = useState(twitterImg)

  const { data } = props;
  return (
    <section id="team"  className="team s2">
      <div className="shape right"></div>
      <img src={line} alt="" className="img-line" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>Team Members</span>
              </h6>
              <h3 className="heading wow" data-splitting>
                Our Amazing Team
                <br />
                Members
              </h3>
            </div>
          </div>

          <div className="team__container">
            {data.map((idx) => (
              <div key={idx.id}>
                <div className="team-box">
                  <div className="image">
                    <Link to="/team">
                      <img src={idx.img} alt="DeliverE" />
                    </Link>

                    <ul className="list-social">
                      <li>
                        <a href={idx.twitter} target="_blank">
                        <img src={twitter} onMouseOver={()=>setTwitter(twitterWhiteImg)} onMouseLeave={()=>setTwitter(twitterImg)} className="teams__icon" alt="twitter" />
                        </a>
                      </li>
                      <li>
                        <a href={idx.telegram} target="_blank">
                        <img src={telegram} onMouseOver={()=>setTelegram(telegramWhiteImg)} onMouseLeave={()=>setTelegram(telegramImg)} className="teams__icon" alt="telegram" />
                        </a>
                      </li>
                      <li>
                        <a href={idx.linkedin} target="_blank">
                          <img src={linkedin} onMouseOver={()=>setlinkedin(linkedinWhiteImg)} onMouseLeave={()=>setlinkedin(linkedinImg)} className="teams__icon" alt="discord" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="content">
                    <Link to="/team" className="h5 name">
                      {idx.name}
                    </Link>
                    <p className="postion">{idx.position}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team2;
