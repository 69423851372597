import React, { useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";

import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import img1 from "../../assets/images/layouts/banner.png";
import img3 from "../../assets/images/layouts/avt-01.png";
import walletx from "../../assets/images/icon/walletx.png";

Banner2.propTypes = {
  data: PropTypes.array,
};

function Banner2(props) {
  const { data } = props;

  return (
    <section className="banner s2">
      <div className="home__container">
            <div className="block-text center">
              <h6 className="sub-heading mb-5">
                <span>Welcome to DeliverE</span>
              </h6>
              <h2 className="heading pb-4 heading__height m-auto mt-5">
              We empower web3 brands to connect with their users using our
                <span className="s1 arlo_tm_animation_text_word mx-3 mb-5">
                Revolutionary 
                </span>
                web3 native ad engine
              </h2>
              {/* <p className="desc">
                We bridge the gap between Crypto Wallet Developers and Crypto Advertisers. Our innovative platform allows wallet users to enjoy gasless transactions while offering advertisers a targeted approach to reach crypto enthusiasts. Dive into a world where transactions are smoother, and advertising is smarter
              </p> */}

              <a href="#advertiser" className="action-btn">
                <span>For Advertisers</span>
              </a>
              <a href="#integrators" className="action-btn">
                <span className="px-4">For Wallet Integrators</span>
              </a>
            </div>

            {/* <div className="banner__right">
              <div className="image">
                <img src={img1} alt="DeliverE" />
              </div>

              <div className="price">
                <div className="icon">
                  <img src={walletx} className="bg-black" alt="DeliverE" />
                </div>
                <div className="content">
                  <h5>Wallet</h5>
                  <p>Providers</p>
                </div>
              </div>

              <div className="owner">
                <div className="image">
                  <img src={img3} alt="DeliverE" />
                </div>
                <div className="content">
                  <h5>Advertisers</h5>
                </div>
              </div>
            </div> */}
      </div>
    </section>
  );
}

export default Banner2;
