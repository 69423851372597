import React, { useState, useEffect } from "react";
import dummy1 from "../../assets/images/temp/1.png";
import dummy2 from "../../assets/images/temp/2.png";
import dummy3 from "../../assets/images/temp/3.png";
import dummy4 from "../../assets/images/temp/4.png";
import layout from "../../assets/images/layouts/blog-detail.png";
import "../../update.css"

function Speciality(props) {
  const { data } = props;
  const [dataBlock] = useState({
    heading: "How it works",
    // desc: 'DeliverE is the premier marketplace for nfties, which are digital items you can truly own for yourself' ,
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [stopChanging, setStopChanging] = useState(true);

  const handleMouseHover = (index) => {
    setCurrentImageIndex(index);
    setStopChanging(true);
  };

  const handleMouseLeave = () => {
    setStopChanging(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!stopChanging) {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 4);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [stopChanging]);

  return (
    <section id="howitworks" className="speciality p-4 my-5">
      <div  className="container">
          <div  className="d-flex flex-column justify-content-center align-items-center block-text">

          <h6  className="sub-heading mb-5">
                <span>How it works</span>
            </h6>

            <img  src={layout} alt="working gif" className=" howitworks__img" />
          </div>
        <p></p>
      </div>
      {/* <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h3 className="heading pd">{dataBlock.heading}</h3>
                                    <p className="">{dataBlock.desc}</p>
                            </div>
                        </div>
                        {
                            data.map((idx, index) => (
                                <div key={idx.id} onMouseOver={()=>handleMouseHover(index)} onMouseLeave={handleMouseLeave} className="col-xl-3 col-md-6">
                                    <div className={`${currentImageIndex === index && "selected__div__speciality"} speciality-box`}>
                                        <div className="icon">
                                            <img src={idx.img} alt="DeliverE" />
                                        </div>
                                        <h5 className="title">{idx.title}</h5>
                                        <p>{idx.desc}</p>
                                        <h3 className="number">0{idx.id}</h3>
                                    </div>
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
                <div className='my-auto '>
                    <img className={currentImageIndex === 0 ? "image__div ratio ratio-1x1" : 'visually-hidden'} src={currentImageIndex === 0 ? dummy1 : ''} alt='img' />
                    <img  className={currentImageIndex === 1 ? "image__div ratio ratio-1x1" : 'visually-hidden'} src={currentImageIndex === 1 ? dummy2 : ''} alt='img' />
                    <img  className={currentImageIndex === 2 ? "image__div ratio ratio-1x1" : 'visually-hidden'} src={currentImageIndex === 2 ? dummy3 : ''} alt='img' />
                    <img  className={currentImageIndex === 3 ? "image__div ratio ratio-1x1" : 'visually-hidden'} src={currentImageIndex === 3 ? dummy4 : ''} alt='img' />
                </div> */}
    </section>
  );
}

export default Speciality;
