import React, { useState } from "react";

import img1 from "../../assets/images/PNG/OBJECT ILLUSTRATION-07.png";
import img2 from "../../assets/images/PNG/OBJECT ILLUSTRATION-02.png";
import img3 from "../../assets/images/PNG/OBJECT ILLUSTRATION-08.png";
import img5 from "../../assets/images/PNG/OBJECT ILLUSTRATION-12.png";
import Button from "../button/Button";
import Partner from "../partner/Partner";

function About(props) {
  const [dataImg] = useState([
    {
      id: 1,
      img: img1,
      class: "img1",
    },
    {
      id: 2,
      img: img2,
      class: "img2",
    },
    {
      id: 3,
      img: img3,
      class: "img3",
    },

    {
      id: 5,
      img: img5,
      class: "img5",
    },
  ]);

  const [dataBlock] = useState({
    subheading: "What we do",
    heading: "Our Journey of Revolutionising Advertisement Industry",
    desc1:
      "We bridge the gap between Crypto Wallet Developers and Crypto Advertisers. Our innovative platform allows wallet users to enjoy gasless transactions while offering advertisers a targeted approach to reach crypto enthusiasts. Dive into a world where transactions are smoother, and advertising is smarter",
    desc2:
      "It is focused   Web3 Advertising Market where currently social media marketing has higher costs.  ",
  });
  return (
    <section className="about my-5">
      <div className="shape"></div>
      <div className="container">
        <div className="row rev">
          <div className="col-xl-6 col-md-12">
            <div className="about__right">
              <div className="images">
                {dataImg.map((idx) => (
                  <img
                    key={idx.id}
                    className={idx.class}
                    src={idx.img}
                    alt="DeliverE"
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p className="mb-17 fs-16">{dataBlock.desc1}</p>
              {/* <Button link="/about" title="More About Us" /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <Partner /> */}
    </section>
  );
}

export default About;
