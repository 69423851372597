import arjunPFP from "../pfpImages/arjun.png";
import surajPFP from "../pfpImages/suraj.png";
import rohitPFP from "../pfpImages/rohit.png";
import bhumikPFP from "../pfpImages/bhumik.png";
import shaktiPFP from "../pfpImages/shakti.png";
import sandeepPFP from "../pfpImages/sandeep.png";

const dataTeam = [
  {
    id: 1,
    img: arjunPFP,
    name: "Arjun ",
    position: "Founder, CEO",
    linkedin: "https://www.linkedin.com/in/itsarjn/",
    telegram: "https://web.telegram.org/k/#@itsarjn",
    twitter: "https://twitter.com/itsarjn",
  },
  {
    id: 2,
    img: surajPFP,
    name: "Suraj Singla",
    position: "Founder, CTO",
    linkedin: "https://www.linkedin.com/in/suraj-singla-9a4343142/",
    telegram: "https://web.telegram.org/k/#@surajsingla333",
    twitter: "https://twitter.com/surajsingla333",
  },

  {
    id: 3,
    img: rohitPFP,
    name: "Rohit",
    position: "Founder, CMO",
    linkedin: "https://www.linkedin.com/in/cryptorohittt/",
    telegram: "https://web.telegram.org/k/#@cryptorohittt",
    twitter: "https://twitter.com/cryptorohittt",
  },

  {
    id: 4,
    img: bhumikPFP,
    name: "Bhumik Prajapati",
    position: "Founding Engineer",
    linkedin: "https://www.linkedin.com/in/bhumik-prajapati-8182181b1/",
    telegram: "https://web.telegram.org/k/#@bmp002",
    twitter: "https://twitter.com/BhumikPrajapat1",
  },

  {
    id: 5,
    img: shaktiPFP,
    name: "Shakti Dubey",
    position: "Founding Engineer",
    linkedin: "https://www.linkedin.com/in/dubeyshakti/",
    telegram: "https://web.telegram.org/k/#@dubeyshakti",
    twitter: "https://twitter.com/shaktidubey_",
  },
  {
    id: 6,
    img: sandeepPFP,
    name: "Sandeep Prajapati",
    position: "Founding Engineer",
    linkedin: "https://www.linkedin.com/in/sandeep-prajapatii/",
    telegram: "https://web.telegram.org/k/#@sandeepprajapati",
    twitter: "https://twitter.com/sandeep_heree",
  },
];

export default dataTeam;
