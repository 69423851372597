import React from "react";
import dataBox from "../../assets/fake-data/data-box";
import dataPortfolio from "../../assets/fake-data/data-portfolio";

import dataBlog from "../../assets/fake-data/data-blog";
import dataCard from "../../assets/fake-data/data-card";
import dataFaqs from "../../assets/fake-data/data-faq";
import dataItem from "../../assets/fake-data/data-item";
import dataRoadMap from "../../assets/fake-data/data-roadmap";
import dataTestimonials2 from "../../assets/fake-data/data-testimonials2";
import dataTeam from "../../assets/fake-data/dataTeam";
import dataAdvertise from "../../assets/fake-data/data-advertise"
import dataWeb3users from "../../assets/fake-data/data-web3users";
import About2 from "../../components/about/About2";
import Banner2 from "../../components/banner/Banner2";
import Blog from "../../components/blog/Blog";
import Counter from "../../components/counter/Counter";
import Faqs from "../../components/faqs/Faqs";
import Footer2 from "../../components/footer/Footer2";
import Project2 from "../../components/project/Project2";
import Roadmap2 from "../../components/roadmap/Roadmap2";
import Team2 from "../../components/team/Team2";
import Testimonials2 from "../../components/testimonials/Testimonials2";
import Speciality from "../../components/speciality/Speciality";
import Portfolio from "../../components/portfolio/Portfolio";
import Partner from "../../components/partner/Partner";
import About from "../../components/about/About";
import Team from "../../components/team/Team";
import Roadmap from "../../components/roadmap/Roadmap";
import Faq3 from "../../components/faqs/Faq3";
import Advertise from "../../components/advertise/Advertise";
import "../../update.css"
import Web3users from "../../components/web3users/Web3user";

function Home02(props) {
  return (
    <div className="home-2 wrapper">
      <Banner2 data={dataCard} />

      <About />

      <Speciality data={dataBox} />
      <Portfolio data={dataPortfolio} />

      <Advertise data={dataAdvertise}/>
      {/* <Web3users data={dataWeb3users}/> */}

      {/* <Roadmap data={dataRoadMap} /> */}
      <Team2 data={dataTeam} />
      <Faq3 data={dataFaqs} />
      <Footer2 />

      {/* <About2 /> */}
      {/* <Roadmap2 data={dataRoadMap} /> */}
      {/* <Counter /> */}
      {/* <Project2 data={dataItem} /> */}
      {/* <Team data={dataTeam} /> */}
      {/* <Testimonials2 data={dataTestimonials2} /> */}
      {/* <Faqs data={dataFaqs} /> */}
      {/* <Blog data={dataBlog} /> */}
    </div>
  );
}

export default Home02;
