const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  // {
  //     id: 2,
  //   name: "About",
  //   links: "/about",
  // },
  {
    id: 2,
    name: "How it works",
    links: "/#howitworks",
  },
  {
    id: 3,
    name: "Team",
    links: "/#team",
  },
  {
    id: 4,
    name: "FAQs",
    links: "/#faqs",
  },
];

export default menus;
